// LICENSE_CODE MIT
/* eslint-disable */
export default {
  "magic": "config_ext_prod",
  "is_sbase": false,
  "front": {
    "company_lbl": "Toolium",
    "url": "https://toolium.org",
    "email": "support@toolium.org",
    "phone": "+18777755232",
    "addr_lbl": "Arye Shenkar St 1, Herzliya, Israel, 4672501"
  },
  "back": {
    "app": {
      "url": "https://api.toolium.org"
    },
    "tcoder": {
      "url": "http://localhost:4001"
    }
  },
  "sentry": {
    "front": {
      "dsn": "https://148abd332c0b4bb1b23c04f8b2dbb6dc@o389619.ingest.sentry.io/4504057153519616"
    },
    "back": {
      "dsn": "https://4a298aaa3ab54f3799dad5064dad2c12@o389619.ingest.sentry.io/6716620"
    }
  },
  "auth0": {
    "domain": "acmetools.eu.auth0.com",
    "client_id": "tngHnKbAI8DIdcUlJ047IDbl24Bf4Kss",
    "audience": "https://acmetools.eu.auth0.com/api/v2/"
  },
  "paypal": {
    "account_id": "sb-amgt522010772@business.example.com",
    "client_id": "AcFzhzbjJwcMwfdjropQuJ8P7JCtu6YoV8G8nRfh5YfsAqWQFkgUbsLO3euh0ZptdowmnNW9rRqvaZKV"
  },
  "google": {
    "analytic_id": "G-5C87L83Z8R"
  },
  "sbase": {
    "url": "https://uzttxbblztzxndwdojqz.supabase.co",
    "anon_pub": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV6dHR4YmJsenR6eG5kd2RvanF6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA2MDU5NTMsImV4cCI6MjAzNjE4MTk1M30.OqWcmr7SiiFd4qc2NqRhbXKZl2YNhyqshId5Yv_Mfaw",
    "is_google": false,
    "is_email_corp": false
  },
  "clarity": "e9rcrynz0f",
  "domain": "toolium.org",
  "is_prod": true,
  "ver": "1.1.377",
  "is_local": false,
  "stage": "prod",
  "bucket_ext": "tlm-front-share-prod"
}